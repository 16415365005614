import { useEffect } from "react";
import { Button, BSPBody1, Grid } from "@bigspring/core-components";
import { RefreshIcon } from "@bigspring/core-components/icons";
import { DoodleHeader } from "../DoodleHeader";
import { FormattedMessage } from "react-intl";
import { useSegment } from "../Segment";
import { ERROR_DISPLAYED } from "@lib/events";
import { useCustomRouter } from "../CustomRouter";
import { useDataDog } from "@shared/DatadogProvider";
import Link from "next/link";

export type ErrorViewProps = {
  dismiss?: () => void;
  tryRefresh?: () => void;
  message?: string;
  showPageNotFound?: boolean;
};

export function ErrorView({
  dismiss,
  showPageNotFound,
  message,
}: ErrorViewProps) {
  const { pathname, push } = useCustomRouter();
  const { captureException } = useDataDog();
  const { page } = useSegment();

  useEffect(() => {
    void page("Generic Error", { pathname });

    if (message) {
      void page(
        ERROR_DISPLAYED.name,
        ERROR_DISPLAYED.properties({
          errorMessage: message,
        })
      );

      void captureException(new Error(message));
    }
  }, [pathname, message, page, captureException]);

  return (
    <>
      <DoodleHeader>
        <RefreshIcon />
      </DoodleHeader>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        {showPageNotFound && (
          <Grid item>
            <p>
              <FormattedMessage
                id="error.page_not_found"
                defaultMessage="Page not Found!"
              />
            </p>
          </Grid>
        )}
        <Grid item>
          <p>
            <FormattedMessage
              id="error.url-auth-userid-configuration-issue"
              defaultMessage="Please reach out to the support team."
            />{" "}
          </p>
        </Grid>

        <Grid item>
          <Link href="/">
            <Button
              type="button"
              onClick={dismiss ? dismiss : () => void push("home")}
              title="Back to home"
              id="error.back.toLearner"
            >
              <BSPBody1 style={{ fontWeight: 500 }}>
                <FormattedMessage
                  defaultMessage="Back to home"
                  id="error.back_to_home"
                />
              </BSPBody1>
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}
