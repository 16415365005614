"use client";

import { ErrorView } from "@shared/ErrorView";
import { IntlProvider } from "@shared/IntlProvider";

// eslint-disable-next-line import/no-default-export
export default function NotFound() {
  // We don't have IntlProvider at this level so we need to wrap the page with it
  // for the FormatMessage(s) to not throw an error. This will always be english
  // though since the user is not logged in at this level.
  // As of now Next Js does not catch route segments not found for not-found.tsx files in inner
  // directory levels, it only catches those when the not found is thrown manually by calling the
  // notFound() function: https://nextjs.org/docs/app/api-reference/file-conventions/not-found
  // TODO: Check this again in the future to see if they implemented that support. In that case,
  // we can create another not-found.tsx within (authenticated) path for logged in users to see
  // the page in their selected language.
  return (
    <IntlProvider>
      <ErrorView showPageNotFound />
    </IntlProvider>
  );
}
